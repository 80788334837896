/*!
 *  @preserve
 *
 * ++++++++++++++++++++++++++++++++++++++
 * Add to Calendar TimeZones iCal Library
 * ++++++++++++++++++++++++++++++++++++++
 *
 * Version: 1.8.2
 * Creator: Jens Kuerschner (https://jenskuerschner.de)
 * Project: https://github.com/add2cal/timezones-ical-library
 * License: Apache-2.0
 *
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const tzlibVersion = '1.8.2';
const tzlibZonesDB = {
  Zulu: ['Etc/UTC', 0],
  WET: ['', 1],
  'W-SU': ['Europe/Moscow', 2],
  Universal: ['Etc/UTC', 0],
  UTC: ['Etc/UTC', 0],
  UCT: ['Etc/UTC', 0],
  Turkey: ['Europe/Istanbul', 3],
  Singapore: ['Asia/Singapore', 4],
  ROK: ['Asia/Seoul', 5],
  ROC: ['Asia/Taipei', 6],
  Portugal: ['Europe/Lisbon', 7],
  Poland: ['Europe/Warsaw', 8],
  PST8PDT: ['', 9],
  PRC: ['Asia/Shanghai', 6],
  Navajo: ['America/Denver', 10],
  NZ: ['Pacific/Auckland', 11],
  'NZ-CHAT': ['Pacific/Chatham', 12],
  MST7MDT: ['', 10],
  MST: ['', 13],
  MET: ['', 14],
  Libya: ['Africa/Tripoli', 15],
  Kwajalein: ['Pacific/Kwajalein', 16],
  Japan: ['Asia/Tokyo', 17],
  Jamaica: ['America/Jamaica', 18],
  Israel: ['Asia/Jerusalem', 19],
  Iran: ['Asia/Tehran', 20],
  Iceland: ['Africa/Abidjan', 21],
  Hongkong: ['Asia/Hong_Kong', 22],
  HST: ['', 23],
  Greenwich: ['Etc/GMT', 24],
  GMT0: ['Etc/GMT', 24],
  GMT: ['Etc/GMT', 24],
  'GMT-0': ['Etc/GMT', 24],
  'GMT+0': ['Etc/GMT', 24],
  GB: ['Europe/London', 25],
  'GB-Eire': ['Europe/London', 25],
  Eire: ['Europe/Dublin', 26],
  Egypt: ['Africa/Cairo', 27],
  EST5EDT: ['', 28],
  EST: ['', 18],
  EET: ['', 29],
  Cuba: ['America/Havana', 30],
  CST6CDT: ['', 31],
  CET: ['', 8],
  US: {
    Samoa: ['Pacific/Pago_Pago', 32],
    Pacific: ['America/Los_Angeles', 9],
    Mountain: ['America/Denver', 10],
    Michigan: ['America/Detroit', 28],
    'Indiana-Starke': ['America/Indiana/Knox', 31],
    Hawaii: ['Pacific/Honolulu', 23],
    Eastern: ['America/New_York', 28],
    'East-Indiana': ['America/Indiana/Indianapolis', 28],
    Central: ['America/Chicago', 31],
    Arizona: ['America/Phoenix', 13],
    Aleutian: ['America/Adak', 33],
    Alaska: ['America/Anchorage', 34],
  },
  Pacific: {
    Yap: ['Pacific/Port_Moresby', 35],
    Wallis: ['Pacific/Tarawa', 16],
    Wake: ['Pacific/Tarawa', 16],
    Truk: ['Pacific/Port_Moresby', 35],
    Tongatapu: ['', 36],
    Tarawa: ['', 16],
    Tahiti: ['', 37],
    Samoa: ['Pacific/Pago_Pago', 32],
    Saipan: ['Pacific/Guam', 38],
    Rarotonga: ['', 37],
    Port_Moresby: ['', 35],
    Ponape: ['Pacific/Guadalcanal', 39],
    Pohnpei: ['Pacific/Guadalcanal', 39],
    Pitcairn: ['', 40],
    Palau: ['', 41],
    Pago_Pago: ['', 32],
    Noumea: ['', 39],
    Norfolk: ['', 42],
    Niue: ['', 43],
    Nauru: ['', 16],
    Midway: ['Pacific/Pago_Pago', 32],
    Marquesas: ['', 44],
    Majuro: ['Pacific/Tarawa', 16],
    Kwajalein: ['', 16],
    Kosrae: ['', 39],
    Kiritimati: ['', 45],
    Kanton: ['', 36],
    Johnston: ['Pacific/Honolulu', 23],
    Honolulu: ['', 23],
    Guam: ['', 38],
    Guadalcanal: ['', 39],
    Gambier: ['', 46],
    Galapagos: ['', 47],
    Funafuti: ['Pacific/Tarawa', 16],
    Fiji: ['', 16],
    Fakaofo: ['', 36],
    Enderbury: ['Pacific/Kanton', 36],
    Efate: ['', 39],
    Easter: ['', 48],
    Chuuk: ['Pacific/Port_Moresby', 35],
    Chatham: ['', 12],
    Bougainville: ['', 39],
    Auckland: ['', 11],
    Apia: ['', 36],
  },
  Mexico: {
    General: ['America/Mexico_City', 49],
    BajaSur: ['America/Mazatlan', 13],
    BajaNorte: ['America/Tijuana', 9],
  },
  Indian: {
    Reunion: ['Asia/Dubai', 50],
    Mayotte: ['Africa/Nairobi', 51],
    Mauritius: ['', 50],
    Maldives: ['', 52],
    Mahe: ['Asia/Dubai', 50],
    Kerguelen: ['Indian/Maldives', 52],
    Comoro: ['Africa/Nairobi', 51],
    Cocos: ['Asia/Yangon', 53],
    Christmas: ['Asia/Bangkok', 54],
    Chagos: ['', 55],
    Antananarivo: ['Africa/Nairobi', 51],
  },
  Europe: {
    Zurich: ['', 8],
    Zaporozhye: ['Europe/Kyiv', 56],
    Zagreb: ['Europe/Belgrade', 8],
    Warsaw: ['', 8],
    Volgograd: ['', 2],
    Vilnius: ['', 29],
    Vienna: ['', 8],
    Vatican: ['Europe/Rome', 8],
    Vaduz: ['Europe/Zurich', 8],
    Uzhgorod: ['Europe/Kyiv', 56],
    Ulyanovsk: ['', 50],
    Tiraspol: ['Europe/Chisinau', 57],
    Tirane: ['', 8],
    Tallinn: ['', 29],
    Stockholm: ['Europe/Berlin', 8],
    Sofia: ['', 29],
    Skopje: ['Europe/Belgrade', 8],
    Simferopol: ['', 2],
    Saratov: ['', 50],
    Sarajevo: ['Europe/Belgrade', 8],
    San_Marino: ['Europe/Rome', 8],
    Samara: ['', 50],
    Rome: ['', 8],
    Riga: ['', 29],
    Prague: ['', 8],
    Podgorica: ['Europe/Belgrade', 8],
    Paris: ['', 8],
    Oslo: ['Europe/Berlin', 8],
    Nicosia: ['Asia/Nicosia', 56],
    Moscow: ['', 2],
    Monaco: ['Europe/Paris', 8],
    Minsk: ['', 3],
    Mariehamn: ['Europe/Helsinki', 29],
    Malta: ['', 8],
    Madrid: ['', 8],
    Luxembourg: ['Europe/Brussels', 8],
    London: ['', 25],
    Ljubljana: ['Europe/Belgrade', 8],
    Lisbon: ['', 7],
    Kyiv: ['', 56],
    Kirov: ['', 2],
    Kiev: ['Europe/Kyiv', 56],
    Kaliningrad: ['', 15],
    Jersey: ['Europe/London', 25],
    Istanbul: ['', 3],
    Isle_of_Man: ['Europe/London', 25],
    Helsinki: ['', 29],
    Guernsey: ['Europe/London', 25],
    Gibraltar: ['', 8],
    Dublin: ['', 26],
    Copenhagen: ['Europe/Berlin', 8],
    Chisinau: ['', 57],
    Busingen: ['Europe/Zurich', 8],
    Budapest: ['', 8],
    Bucharest: ['', 29],
    Brussels: ['', 8],
    Bratislava: ['Europe/Prague', 8],
    Berlin: ['', 8],
    Belgrade: ['', 8],
    Belfast: ['Europe/London', 25],
    Athens: ['', 29],
    Astrakhan: ['', 50],
    Andorra: ['', 8],
    Amsterdam: ['Europe/Brussels', 8],
  },
  Etc: {
    Zulu: ['Etc/UTC', 0],
    Universal: ['Etc/UTC', 0],
    UTC: ['', 0],
    UCT: ['Etc/UTC', 0],
    Greenwich: ['Etc/GMT', 24],
    GMT0: ['Etc/GMT', 24],
    GMT: ['', 24],
    'GMT-9': ['', 58],
    'GMT-8': ['', 59],
    'GMT-7': ['', 60],
    'GMT-6': ['', 61],
    'GMT-5': ['', 62],
    'GMT-4': ['', 63],
    'GMT-3': ['', 64],
    'GMT-2': ['', 65],
    'GMT-14': ['', 66],
    'GMT-13': ['', 67],
    'GMT-12': ['', 68],
    'GMT-11': ['', 69],
    'GMT-10': ['', 70],
    'GMT-1': ['', 71],
    'GMT-0': ['Etc/GMT', 24],
    'GMT+9': ['', 72],
    'GMT+8': ['', 73],
    'GMT+7': ['', 74],
    'GMT+6': ['', 47],
    'GMT+5': ['', 75],
    'GMT+4': ['', 76],
    'GMT+3': ['', 77],
    'GMT+2': ['', 78],
    'GMT+12': ['', 79],
    'GMT+11': ['', 80],
    'GMT+10': ['', 81],
    'GMT+1': ['', 82],
    'GMT+0': ['Etc/GMT', 24],
  },
  Chile: { EasterIsland: ['Pacific/Easter', 48], Continental: ['America/Santiago', 83] },
  Canada: {
    Yukon: ['America/Whitehorse', 13],
    Saskatchewan: ['America/Regina', 49],
    Pacific: ['America/Vancouver', 9],
    Newfoundland: ['America/St_Johns', 84],
    Mountain: ['America/Edmonton', 10],
    Eastern: ['America/Toronto', 28],
    Central: ['America/Winnipeg', 31],
    Atlantic: ['America/Halifax', 85],
  },
  Brazil: {
    West: ['America/Manaus', 86],
    East: ['America/Sao_Paulo', 87],
    DeNoronha: ['America/Noronha', 88],
    Acre: ['America/Rio_Branco', 89],
  },
  Australia: {
    Yancowinna: ['Australia/Broken_Hill', 90],
    West: ['Australia/Perth', 91],
    Victoria: ['Australia/Melbourne', 92],
    Tasmania: ['Australia/Hobart', 93],
    Sydney: ['', 92],
    South: ['Australia/Adelaide', 90],
    Queensland: ['Australia/Brisbane', 94],
    Perth: ['', 91],
    North: ['Australia/Darwin', 95],
    NSW: ['Australia/Sydney', 92],
    Melbourne: ['', 92],
    Lord_Howe: ['', 96],
    Lindeman: ['', 94],
    LHI: ['Australia/Lord_Howe', 96],
    Hobart: ['', 93],
    Eucla: ['', 97],
    Darwin: ['', 95],
    Currie: ['Australia/Hobart', 93],
    Canberra: ['Australia/Sydney', 92],
    Broken_Hill: ['', 90],
    Brisbane: ['', 94],
    Adelaide: ['', 90],
    ACT: ['Australia/Sydney', 92],
  },
  Atlantic: {
    Stanley: ['', 77],
    St_Helena: ['Africa/Abidjan', 21],
    South_Georgia: ['', 78],
    Reykjavik: ['Africa/Abidjan', 21],
    Madeira: ['', 1],
    Jan_Mayen: ['Europe/Berlin', 8],
    Faroe: ['', 1],
    Faeroe: ['Atlantic/Faroe', 1],
    Cape_Verde: ['', 98],
    Canary: ['', 1],
    Bermuda: ['', 85],
    Azores: ['', 99],
  },
  Asia: {
    Yerevan: ['', 50],
    Yekaterinburg: ['', 52],
    Yangon: ['', 53],
    Yakutsk: ['', 41],
    Vladivostok: ['', 35],
    Vientiane: ['Asia/Bangkok', 54],
    'Ust-Nera': ['', 35],
    Urumqi: ['', 55],
    Ulan_Bator: ['Asia/Ulaanbaatar', 4],
    Ulaanbaatar: ['', 4],
    Ujung_Pandang: ['Asia/Makassar', 100],
    Tomsk: ['', 54],
    Tokyo: ['', 17],
    Thimphu: ['', 55],
    Thimbu: ['Asia/Thimphu', 55],
    Tel_Aviv: ['Asia/Jerusalem', 19],
    Tehran: ['', 20],
    Tbilisi: ['', 50],
    Tashkent: ['', 52],
    Taipei: ['', 6],
    Srednekolymsk: ['', 39],
    Singapore: ['', 4],
    Shanghai: ['', 6],
    Seoul: ['', 5],
    Samarkand: ['', 52],
    Sakhalin: ['', 39],
    Saigon: ['Asia/Ho_Chi_Minh', 54],
    Riyadh: ['', 3],
    Rangoon: ['Asia/Yangon', 53],
    Qyzylorda: ['', 52],
    Qostanay: ['', 52],
    Qatar: ['', 3],
    Pyongyang: ['', 5],
    Pontianak: ['', 101],
    Phnom_Penh: ['Asia/Bangkok', 54],
    Oral: ['', 52],
    Omsk: ['', 55],
    Novosibirsk: ['', 54],
    Novokuznetsk: ['', 54],
    Nicosia: ['', 56],
    Muscat: ['Asia/Dubai', 50],
    Manila: ['', 102],
    Makassar: ['', 100],
    Magadan: ['', 39],
    Macau: ['', 6],
    Macao: ['Asia/Macau', 6],
    Kuwait: ['Asia/Riyadh', 3],
    Kuching: ['', 4],
    Kuala_Lumpur: ['Asia/Singapore', 4],
    Krasnoyarsk: ['', 54],
    Kolkata: ['', 103],
    Khandyga: ['', 41],
    Katmandu: ['Asia/Kathmandu', 104],
    Kathmandu: ['', 104],
    Kashgar: ['Asia/Urumqi', 55],
    Karachi: ['', 105],
    Kamchatka: ['', 16],
    Kabul: ['', 106],
    Jerusalem: ['', 19],
    Jayapura: ['', 107],
    Jakarta: ['', 101],
    Istanbul: ['Europe/Istanbul', 3],
    Irkutsk: ['', 4],
    Hovd: ['', 54],
    Hong_Kong: ['', 22],
    Ho_Chi_Minh: ['', 54],
    Hebron: ['', 108],
    Harbin: ['Asia/Shanghai', 6],
    Gaza: ['', 108],
    Famagusta: ['', 29],
    Dushanbe: ['', 52],
    Dubai: ['', 50],
    Dili: ['', 41],
    Dhaka: ['', 55],
    Damascus: ['', 3],
    Dacca: ['Asia/Dhaka', 55],
    Colombo: ['', 109],
    Chungking: ['Asia/Shanghai', 6],
    Chongqing: ['Asia/Shanghai', 6],
    Choibalsan: ['', 4],
    Chita: ['', 41],
    Calcutta: ['Asia/Kolkata', 103],
    Brunei: ['Asia/Kuching', 4],
    Bishkek: ['', 55],
    Beirut: ['', 110],
    Barnaul: ['', 54],
    Bangkok: ['', 54],
    Baku: ['', 50],
    Bahrain: ['Asia/Qatar', 3],
    Baghdad: ['', 3],
    Atyrau: ['', 52],
    Ashkhabad: ['Asia/Ashgabat', 52],
    Ashgabat: ['', 52],
    Aqtobe: ['', 52],
    Aqtau: ['', 52],
    Anadyr: ['', 16],
    Amman: ['', 3],
    Almaty: ['', 52],
    Aden: ['Asia/Riyadh', 3],
  },
  Arctic: { Longyearbyen: ['Europe/Berlin', 8] },
  Antarctica: {
    Vostok: ['', 52],
    Troll: ['', 111],
    Syowa: ['Asia/Riyadh', 3],
    South_Pole: ['Pacific/Auckland', 11],
    Rothera: ['', 87],
    Palmer: ['', 77],
    McMurdo: ['Pacific/Auckland', 11],
    Mawson: ['', 52],
    Macquarie: ['', 92],
    DumontDUrville: ['Pacific/Port_Moresby', 35],
    Davis: ['', 54],
    Casey: ['', 4],
  },
  America: {
    Yellowknife: ['America/Edmonton', 10],
    Yakutat: ['', 34],
    Winnipeg: ['', 31],
    Whitehorse: ['', 13],
    Virgin: ['America/Puerto_Rico', 112],
    Vancouver: ['', 9],
    Tortola: ['America/Puerto_Rico', 112],
    Toronto: ['', 28],
    Tijuana: ['', 9],
    Thunder_Bay: ['America/Toronto', 28],
    Thule: ['', 85],
    Tegucigalpa: ['', 49],
    Swift_Current: ['', 49],
    St_Vincent: ['America/Puerto_Rico', 112],
    St_Thomas: ['America/Puerto_Rico', 112],
    St_Lucia: ['America/Puerto_Rico', 112],
    St_Kitts: ['America/Puerto_Rico', 112],
    St_Johns: ['', 84],
    St_Barthelemy: ['America/Puerto_Rico', 112],
    Sitka: ['', 34],
    Shiprock: ['America/Denver', 10],
    Scoresbysund: ['', 113],
    Sao_Paulo: ['', 87],
    Santo_Domingo: ['', 112],
    Santiago: ['', 83],
    Santarem: ['', 87],
    Santa_Isabel: ['America/Tijuana', 9],
    Rosario: ['America/Argentina/Cordoba', 87],
    Rio_Branco: ['', 89],
    Resolute: ['', 114],
    Regina: ['', 49],
    Recife: ['', 87],
    Rankin_Inlet: ['', 31],
    Rainy_River: ['America/Winnipeg', 31],
    Punta_Arenas: ['', 87],
    Puerto_Rico: ['', 112],
    Porto_Velho: ['', 86],
    Porto_Acre: ['America/Rio_Branco', 89],
    Port_of_Spain: ['America/Puerto_Rico', 112],
    'Port-au-Prince': ['', 28],
    Phoenix: ['', 13],
    Paramaribo: ['', 77],
    Pangnirtung: ['America/Iqaluit', 28],
    Panama: ['', 18],
    Ojinaga: ['', 31],
    Nuuk: ['', 115],
    Noronha: ['', 88],
    Nome: ['', 34],
    Nipigon: ['America/Toronto', 28],
    New_York: ['', 28],
    Nassau: ['America/Toronto', 28],
    Montserrat: ['America/Puerto_Rico', 112],
    Montreal: ['America/Toronto', 28],
    Montevideo: ['', 77],
    Monterrey: ['', 49],
    Moncton: ['', 85],
    Miquelon: ['', 116],
    Mexico_City: ['', 49],
    Metlakatla: ['', 34],
    Merida: ['', 49],
    Menominee: ['', 31],
    Mendoza: ['America/Argentina/Mendoza', 87],
    Mazatlan: ['', 13],
    Matamoros: ['', 31],
    Martinique: ['', 112],
    Marigot: ['America/Puerto_Rico', 112],
    Manaus: ['', 86],
    Managua: ['', 49],
    Maceio: ['', 87],
    Lower_Princes: ['America/Puerto_Rico', 112],
    Louisville: ['America/Kentucky/Louisville', 28],
    Los_Angeles: ['', 9],
    Lima: ['', 75],
    La_Paz: ['', 86],
    Kralendijk: ['America/Puerto_Rico', 112],
    Knox_IN: ['America/Indiana/Knox', 31],
    Juneau: ['', 34],
    Jujuy: ['America/Argentina/Jujuy', 87],
    Jamaica: ['', 18],
    Iqaluit: ['', 28],
    Inuvik: ['', 10],
    Indianapolis: ['America/Indiana/Indianapolis', 28],
    Hermosillo: ['', 13],
    Havana: ['', 30],
    Halifax: ['', 85],
    Guyana: ['', 76],
    Guayaquil: ['', 75],
    Guatemala: ['', 49],
    Guadeloupe: ['America/Puerto_Rico', 112],
    Grenada: ['America/Puerto_Rico', 112],
    Grand_Turk: ['', 117],
    Goose_Bay: ['', 118],
    Godthab: ['America/Nuuk', 115],
    Glace_Bay: ['', 85],
    Fortaleza: ['', 87],
    Fort_Wayne: ['America/Indiana/Indianapolis', 28],
    Fort_Nelson: ['', 13],
    Ensenada: ['America/Tijuana', 9],
    El_Salvador: ['', 49],
    Eirunepe: ['', 89],
    Edmonton: ['', 10],
    Dominica: ['America/Puerto_Rico', 112],
    Detroit: ['', 28],
    Denver: ['', 10],
    Dawson_Creek: ['', 13],
    Dawson: ['', 13],
    Danmarkshavn: ['', 21],
    Curacao: ['America/Puerto_Rico', 112],
    Cuiaba: ['', 86],
    Creston: ['America/Phoenix', 13],
    Costa_Rica: ['', 49],
    Cordoba: ['America/Argentina/Cordoba', 87],
    Coral_Harbour: ['America/Panama', 18],
    Ciudad_Juarez: ['', 10],
    Chihuahua: ['', 49],
    Chicago: ['', 31],
    Cayman: ['America/Panama', 18],
    Cayenne: ['', 77],
    Catamarca: ['America/Argentina/Catamarca', 87],
    Caracas: ['', 76],
    Cancun: ['', 18],
    Campo_Grande: ['', 86],
    Cambridge_Bay: ['', 10],
    Buenos_Aires: ['America/Argentina/Buenos_Aires', 87],
    Boise: ['', 10],
    Bogota: ['', 75],
    Boa_Vista: ['', 86],
    'Blanc-Sablon': ['America/Puerto_Rico', 112],
    Belize: ['', 49],
    Belem: ['', 87],
    Barbados: ['', 112],
    Bahia_Banderas: ['', 49],
    Bahia: ['', 87],
    Atka: ['America/Adak', 33],
    Atikokan: ['America/Panama', 18],
    Asuncion: ['', 119],
    Aruba: ['America/Puerto_Rico', 112],
    Araguaina: ['', 87],
    Antigua: ['America/Puerto_Rico', 112],
    Anguilla: ['America/Puerto_Rico', 112],
    Anchorage: ['', 34],
    Adak: ['', 33],
    North_Dakota: { New_Salem: ['', 31], Center: ['', 31], Beulah: ['', 31] },
    Kentucky: { Monticello: ['', 28], Louisville: ['', 28] },
    Indiana: {
      Winamac: ['', 117],
      Vincennes: ['', 28],
      Vevay: ['', 28],
      Tell_City: ['', 31],
      Petersburg: ['', 28],
      Marengo: ['', 28],
      Knox: ['', 31],
      Indianapolis: ['', 28],
    },
    Argentina: {
      Ushuaia: ['', 87],
      Tucuman: ['', 87],
      San_Luis: ['', 87],
      San_Juan: ['', 87],
      Salta: ['', 87],
      Rio_Gallegos: ['', 87],
      Mendoza: ['', 87],
      La_Rioja: ['', 87],
      Jujuy: ['', 87],
      Cordoba: ['', 87],
      ComodRivadavia: ['America/Argentina/Catamarca', 87],
      Catamarca: ['', 87],
      Buenos_Aires: ['', 87],
    },
  },
  Africa: {
    Windhoek: ['', 120],
    Tunis: ['', 121],
    Tripoli: ['', 15],
    Timbuktu: ['Africa/Abidjan', 21],
    Sao_Tome: ['', 21],
    'Porto-Novo': ['Africa/Lagos', 122],
    Ouagadougou: ['Africa/Abidjan', 21],
    Nouakchott: ['Africa/Abidjan', 21],
    Niamey: ['Africa/Lagos', 122],
    Ndjamena: ['', 122],
    Nairobi: ['', 51],
    Monrovia: ['', 21],
    Mogadishu: ['Africa/Nairobi', 51],
    Mbabane: ['Africa/Johannesburg', 123],
    Maseru: ['Africa/Johannesburg', 123],
    Maputo: ['', 120],
    Malabo: ['Africa/Lagos', 122],
    Lusaka: ['Africa/Maputo', 120],
    Lubumbashi: ['Africa/Maputo', 120],
    Luanda: ['Africa/Lagos', 122],
    Lome: ['Africa/Abidjan', 21],
    Libreville: ['Africa/Lagos', 122],
    Lagos: ['', 122],
    Kinshasa: ['Africa/Lagos', 122],
    Kigali: ['Africa/Maputo', 120],
    Khartoum: ['', 120],
    Kampala: ['Africa/Nairobi', 51],
    Juba: ['', 120],
    Johannesburg: ['', 123],
    Harare: ['Africa/Maputo', 120],
    Gaborone: ['Africa/Maputo', 120],
    Freetown: ['Africa/Abidjan', 21],
    El_Aaiun: ['', 124],
    Douala: ['Africa/Lagos', 122],
    Djibouti: ['Africa/Nairobi', 51],
    Dar_es_Salaam: ['Africa/Nairobi', 51],
    Dakar: ['Africa/Abidjan', 21],
    Conakry: ['Africa/Abidjan', 21],
    Ceuta: ['', 8],
    Casablanca: ['', 124],
    Cairo: ['', 27],
    Bujumbura: ['Africa/Maputo', 120],
    Brazzaville: ['Africa/Lagos', 122],
    Blantyre: ['Africa/Maputo', 120],
    Bissau: ['', 21],
    Banjul: ['Africa/Abidjan', 21],
    Bangui: ['Africa/Lagos', 122],
    Bamako: ['Africa/Abidjan', 21],
    Asmera: ['Africa/Nairobi', 51],
    Asmara: ['Africa/Nairobi', 51],
    Algiers: ['', 121],
    Addis_Ababa: ['Africa/Nairobi', 51],
    Accra: ['Africa/Abidjan', 21],
    Abidjan: ['', 21],
  },
};
const tzlibZonesDetailsDB = [
  '20240205T192835Z<n><bs><n><tz>UTC<n><of>+0000<n><ot>+0000<n><s>19700101T000000<n><es><n>',
  '20240205T192834Z<n><bd><n><tz>WEST<n><of>+0000<n><ot>+0100<n><s>19700329T010000<n><r>FREQ=YEARLY;BYMONTH=3;BYDAY=-1SU<n><ed><n><bs><n><tz>WET<n><of>+0100<n><ot>+0000<n><s>19701025T020000<n><r>FREQ=YEARLY;BYMONTH=10;BYDAY=-1SU<n><es><n>',
  '20240205T192834Z<n><bs><n><tz>MSK<n><of>+0300<n><ot>+0300<n><s>19700101T000000<n><es><n>',
  '20240205T192834Z<n><bs><n><tz>+03<n><of>+0300<n><ot>+0300<n><s>19700101T000000<n><es><n>',
  '20240205T192834Z<n><bs><n><tz>+08<n><of>+0800<n><ot>+0800<n><s>19700101T000000<n><es><n>',
  '20240205T192834Z<n><bs><n><tz>KST<n><of>+0900<n><ot>+0900<n><s>19700101T000000<n><es><n>',
  '20240205T192834Z<n><bs><n><tz>CST<n><of>+0800<n><ot>+0800<n><s>19700101T000000<n><es><n>',
  '20240205T192834Z<n><bs><n><tz>WET<n><of>+0100<n><ot>+0000<n><s>19701025T020000<n><r>FREQ=YEARLY;BYMONTH=10;BYDAY=-1SU<n><es><n><bd><n><tz>WEST<n><of>+0000<n><ot>+0100<n><s>19700329T010000<n><r>FREQ=YEARLY;BYMONTH=3;BYDAY=-1SU<n><ed><n>',
  '20240205T192834Z<n><bd><n><tz>CEST<n><of>+0100<n><ot>+0200<n><s>19700329T020000<n><r>FREQ=YEARLY;BYMONTH=3;BYDAY=-1SU<n><ed><n><bs><n><tz>CET<n><of>+0200<n><ot>+0100<n><s>19701025T030000<n><r>FREQ=YEARLY;BYMONTH=10;BYDAY=-1SU<n><es><n>',
  '20240205T192834Z<n><bd><n><tz>PDT<n><of>-0800<n><ot>-0700<n><s>19700308T020000<n><r>FREQ=YEARLY;BYMONTH=3;BYDAY=2SU<n><ed><n><bs><n><tz>PST<n><of>-0700<n><ot>-0800<n><s>19701101T020000<n><r>FREQ=YEARLY;BYMONTH=11;BYDAY=1SU<n><es><n>',
  '20240205T192834Z<n><bd><n><tz>MDT<n><of>-0700<n><ot>-0600<n><s>19700308T020000<n><r>FREQ=YEARLY;BYMONTH=3;BYDAY=2SU<n><ed><n><bs><n><tz>MST<n><of>-0600<n><ot>-0700<n><s>19701101T020000<n><r>FREQ=YEARLY;BYMONTH=11;BYDAY=1SU<n><es><n>',
  '20240205T192834Z<n><bd><n><tz>NZDT<n><of>+1200<n><ot>+1300<n><s>19700927T020000<n><r>FREQ=YEARLY;BYMONTH=9;BYDAY=-1SU<n><ed><n><bs><n><tz>NZST<n><of>+1300<n><ot>+1200<n><s>19700405T030000<n><r>FREQ=YEARLY;BYMONTH=4;BYDAY=1SU<n><es><n>',
  '20240205T192834Z<n><bd><n><tz>+1345<n><of>+1245<n><ot>+1345<n><s>19700927T024500<n><r>FREQ=YEARLY;BYMONTH=9;BYDAY=-1SU<n><ed><n><bs><n><tz>+1245<n><of>+1345<n><ot>+1245<n><s>19700405T034500<n><r>FREQ=YEARLY;BYMONTH=4;BYDAY=1SU<n><es><n>',
  '20240205T192834Z<n><bs><n><tz>MST<n><of>-0700<n><ot>-0700<n><s>19700101T000000<n><es><n>',
  '20240205T192834Z<n><bd><n><tz>MEST<n><of>+0100<n><ot>+0200<n><s>19700329T020000<n><r>FREQ=YEARLY;BYMONTH=3;BYDAY=-1SU<n><ed><n><bs><n><tz>MET<n><of>+0200<n><ot>+0100<n><s>19701025T030000<n><r>FREQ=YEARLY;BYMONTH=10;BYDAY=-1SU<n><es><n>',
  '20240205T192834Z<n><bs><n><tz>EET<n><of>+0200<n><ot>+0200<n><s>19700101T000000<n><es><n>',
  '20240205T192834Z<n><bs><n><tz>+12<n><of>+1200<n><ot>+1200<n><s>19700101T000000<n><es><n>',
  '20240205T192834Z<n><bs><n><tz>JST<n><of>+0900<n><ot>+0900<n><s>19700101T000000<n><es><n>',
  '20240205T192834Z<n><bs><n><tz>EST<n><of>-0500<n><ot>-0500<n><s>19700101T000000<n><es><n>',
  '20240205T192834Z<n><bd><n><tz>IDT<n><of>+0200<n><ot>+0300<n><s>19700327T020000<n><r>FREQ=YEARLY;BYMONTH=3;BYDAY=-1FR<n><ed><n><bs><n><tz>IST<n><of>+0300<n><ot>+0200<n><s>19701025T020000<n><r>FREQ=YEARLY;BYMONTH=10;BYDAY=-1SU<n><es><n>',
  '20240205T192834Z<n><bs><n><tz>+0330<n><of>+0330<n><ot>+0330<n><s>19700101T000000<n><es><n>',
  '20240205T192834Z<n><bs><n><tz>GMT<n><of>+0000<n><ot>+0000<n><s>19700101T000000<n><es><n>',
  '20240205T192834Z<n><bs><n><tz>HKT<n><of>+0800<n><ot>+0800<n><s>19700101T000000<n><es><n>',
  '20240205T192834Z<n><bs><n><tz>HST<n><of>-1000<n><ot>-1000<n><s>19700101T000000<n><es><n>',
  '20240205T192835Z<n><bs><n><tz>GMT<n><of>+0000<n><ot>+0000<n><s>19700101T000000<n><es><n>',
  '20240205T192834Z<n><bd><n><tz>BST<n><of>+0000<n><ot>+0100<n><s>19700329T010000<n><r>FREQ=YEARLY;BYMONTH=3;BYDAY=-1SU<n><ed><n><bs><n><tz>GMT<n><of>+0100<n><ot>+0000<n><s>19701025T020000<n><r>FREQ=YEARLY;BYMONTH=10;BYDAY=-1SU<n><es><n>',
  '20240205T192834Z<n><bs><n><tz>IST<n><of>+0000<n><ot>+0100<n><s>19700329T010000<n><r>FREQ=YEARLY;BYMONTH=3;BYDAY=-1SU<n><es><n><bd><n><tz>GMT<n><of>+0100<n><ot>+0000<n><s>19701025T020000<n><r>FREQ=YEARLY;BYMONTH=10;BYDAY=-1SU<n><ed><n>',
  '20240205T192834Z<n><bd><n><tz>EEST<n><of>+0200<n><ot>+0300<n><s>19700424T000000<n><r>FREQ=YEARLY;BYMONTH=4;BYDAY=-1FR<n><ed><n><bs><n><tz>EET<n><of>+0300<n><ot>+0200<n><s>19701030T000000<n><r>FREQ=YEARLY;BYMONTH=10;BYDAY=-1FR<n><es><n>',
  '20240205T192834Z<n><bd><n><tz>EDT<n><of>-0500<n><ot>-0400<n><s>19700308T020000<n><r>FREQ=YEARLY;BYMONTH=3;BYDAY=2SU<n><ed><n><bs><n><tz>EST<n><of>-0400<n><ot>-0500<n><s>19701101T020000<n><r>FREQ=YEARLY;BYMONTH=11;BYDAY=1SU<n><es><n>',
  '20240205T192834Z<n><bd><n><tz>EEST<n><of>+0200<n><ot>+0300<n><s>19700329T030000<n><r>FREQ=YEARLY;BYMONTH=3;BYDAY=-1SU<n><ed><n><bs><n><tz>EET<n><of>+0300<n><ot>+0200<n><s>19701025T040000<n><r>FREQ=YEARLY;BYMONTH=10;BYDAY=-1SU<n><es><n>',
  '20240205T192834Z<n><bs><n><tz>CST<n><of>-0400<n><ot>-0500<n><s>19701101T010000<n><r>FREQ=YEARLY;BYMONTH=11;BYDAY=1SU<n><es><n><bd><n><tz>CDT<n><of>-0500<n><ot>-0400<n><s>19700308T000000<n><r>FREQ=YEARLY;BYMONTH=3;BYDAY=2SU<n><ed><n>',
  '20240205T192834Z<n><bd><n><tz>CDT<n><of>-0600<n><ot>-0500<n><s>19700308T020000<n><r>FREQ=YEARLY;BYMONTH=3;BYDAY=2SU<n><ed><n><bs><n><tz>CST<n><of>-0500<n><ot>-0600<n><s>19701101T020000<n><r>FREQ=YEARLY;BYMONTH=11;BYDAY=1SU<n><es><n>',
  '20240205T192834Z<n><bs><n><tz>SST<n><of>-1100<n><ot>-1100<n><s>19700101T000000<n><es><n>',
  '20240205T192834Z<n><bd><n><tz>HDT<n><of>-1000<n><ot>-0900<n><s>19700308T020000<n><r>FREQ=YEARLY;BYMONTH=3;BYDAY=2SU<n><ed><n><bs><n><tz>HST<n><of>-0900<n><ot>-1000<n><s>19701101T020000<n><r>FREQ=YEARLY;BYMONTH=11;BYDAY=1SU<n><es><n>',
  '20240205T192834Z<n><bd><n><tz>AKDT<n><of>-0900<n><ot>-0800<n><s>19700308T020000<n><r>FREQ=YEARLY;BYMONTH=3;BYDAY=2SU<n><ed><n><bs><n><tz>AKST<n><of>-0800<n><ot>-0900<n><s>19701101T020000<n><r>FREQ=YEARLY;BYMONTH=11;BYDAY=1SU<n><es><n>',
  '20240205T192834Z<n><bs><n><tz>+10<n><of>+1000<n><ot>+1000<n><s>19700101T000000<n><es><n>',
  '20240205T192834Z<n><bs><n><tz>+13<n><of>+1300<n><ot>+1300<n><s>19700101T000000<n><es><n>',
  '20240205T192834Z<n><bs><n><tz>-10<n><of>-1000<n><ot>-1000<n><s>19700101T000000<n><es><n>',
  '20240205T192834Z<n><bs><n><tz>ChST<n><of>+1000<n><ot>+1000<n><s>19700101T000000<n><es><n>',
  '20240205T192834Z<n><bs><n><tz>+11<n><of>+1100<n><ot>+1100<n><s>19700101T000000<n><es><n>',
  '20240205T192834Z<n><bs><n><tz>-08<n><of>-0800<n><ot>-0800<n><s>19700101T000000<n><es><n>',
  '20240205T192834Z<n><bs><n><tz>+09<n><of>+0900<n><ot>+0900<n><s>19700101T000000<n><es><n>',
  '20240205T192834Z<n><bd><n><tz>+12<n><of>+1100<n><ot>+1200<n><s>19701004T020000<n><r>FREQ=YEARLY;BYMONTH=10;BYDAY=1SU<n><ed><n><bs><n><tz>+11<n><of>+1200<n><ot>+1100<n><s>19700405T030000<n><r>FREQ=YEARLY;BYMONTH=4;BYDAY=1SU<n><es><n>',
  '20240205T192834Z<n><bs><n><tz>-11<n><of>-1100<n><ot>-1100<n><s>19700101T000000<n><es><n>',
  '20240205T192834Z<n><bs><n><tz>-0930<n><of>-0930<n><ot>-0930<n><s>19700101T000000<n><es><n>',
  '20240205T192834Z<n><bs><n><tz>+14<n><of>+1400<n><ot>+1400<n><s>19700101T000000<n><es><n>',
  '20240205T192834Z<n><bs><n><tz>-09<n><of>-0900<n><ot>-0900<n><s>19700101T000000<n><es><n>',
  '20240205T192835Z<n><bs><n><tz>-06<n><of>-0600<n><ot>-0600<n><s>19700101T000000<n><es><n>',
  '20240205T192835Z<n><bs><n><tz>-06<n><of>-0500<n><ot>-0600<n><s>19700404T220000<n><r>FREQ=YEARLY;BYMONTH=4;BYDAY=1SA<n><es><n><bd><n><tz>-05<n><of>-0600<n><ot>-0500<n><s>19700905T220000<n><r>FREQ=YEARLY;BYMONTH=9;BYDAY=1SA<n><ed><n>',
  '20240205T192834Z<n><bs><n><tz>CST<n><of>-0600<n><ot>-0600<n><s>19700101T000000<n><es><n>',
  '20240205T192834Z<n><bs><n><tz>+04<n><of>+0400<n><ot>+0400<n><s>19700101T000000<n><es><n>',
  '20240205T192834Z<n><bs><n><tz>EAT<n><of>+0300<n><ot>+0300<n><s>19700101T000000<n><es><n>',
  '20240205T192834Z<n><bs><n><tz>+05<n><of>+0500<n><ot>+0500<n><s>19700101T000000<n><es><n>',
  '20240205T192834Z<n><bs><n><tz>+0630<n><of>+0630<n><ot>+0630<n><s>19700101T000000<n><es><n>',
  '20240205T192834Z<n><bs><n><tz>+07<n><of>+0700<n><ot>+0700<n><s>19700101T000000<n><es><n>',
  '20240205T192834Z<n><bs><n><tz>+06<n><of>+0600<n><ot>+0600<n><s>19700101T000000<n><es><n>',
  '20240205T192834Z<n><bs><n><tz>EET<n><of>+0300<n><ot>+0200<n><s>19701025T040000<n><r>FREQ=YEARLY;BYMONTH=10;BYDAY=-1SU<n><es><n><bd><n><tz>EEST<n><of>+0200<n><ot>+0300<n><s>19700329T030000<n><r>FREQ=YEARLY;BYMONTH=3;BYDAY=-1SU<n><ed><n>',
  '20240205T192834Z<n><bd><n><tz>EEST<n><of>+0200<n><ot>+0300<n><s>19700329T020000<n><r>FREQ=YEARLY;BYMONTH=3;BYDAY=-1SU<n><ed><n><bs><n><tz>EET<n><of>+0300<n><ot>+0200<n><s>19701025T030000<n><r>FREQ=YEARLY;BYMONTH=10;BYDAY=-1SU<n><es><n>',
  '20240205T192835Z<n><bs><n><tz>+09<n><of>+0900<n><ot>+0900<n><s>19700101T000000<n><es><n>',
  '20240205T192835Z<n><bs><n><tz>+08<n><of>+0800<n><ot>+0800<n><s>19700101T000000<n><es><n>',
  '20240205T192835Z<n><bs><n><tz>+07<n><of>+0700<n><ot>+0700<n><s>19700101T000000<n><es><n>',
  '20240205T192835Z<n><bs><n><tz>+06<n><of>+0600<n><ot>+0600<n><s>19700101T000000<n><es><n>',
  '20240205T192835Z<n><bs><n><tz>+05<n><of>+0500<n><ot>+0500<n><s>19700101T000000<n><es><n>',
  '20240205T192835Z<n><bs><n><tz>+04<n><of>+0400<n><ot>+0400<n><s>19700101T000000<n><es><n>',
  '20240205T192835Z<n><bs><n><tz>+03<n><of>+0300<n><ot>+0300<n><s>19700101T000000<n><es><n>',
  '20240205T192835Z<n><bs><n><tz>+02<n><of>+0200<n><ot>+0200<n><s>19700101T000000<n><es><n>',
  '20240205T192835Z<n><bs><n><tz>+14<n><of>+1400<n><ot>+1400<n><s>19700101T000000<n><es><n>',
  '20240205T192835Z<n><bs><n><tz>+13<n><of>+1300<n><ot>+1300<n><s>19700101T000000<n><es><n>',
  '20240205T192835Z<n><bs><n><tz>+12<n><of>+1200<n><ot>+1200<n><s>19700101T000000<n><es><n>',
  '20240205T192835Z<n><bs><n><tz>+11<n><of>+1100<n><ot>+1100<n><s>19700101T000000<n><es><n>',
  '20240205T192835Z<n><bs><n><tz>+10<n><of>+1000<n><ot>+1000<n><s>19700101T000000<n><es><n>',
  '20240205T192835Z<n><bs><n><tz>+01<n><of>+0100<n><ot>+0100<n><s>19700101T000000<n><es><n>',
  '20240205T192835Z<n><bs><n><tz>-09<n><of>-0900<n><ot>-0900<n><s>19700101T000000<n><es><n>',
  '20240205T192835Z<n><bs><n><tz>-08<n><of>-0800<n><ot>-0800<n><s>19700101T000000<n><es><n>',
  '20240205T192835Z<n><bs><n><tz>-07<n><of>-0700<n><ot>-0700<n><s>19700101T000000<n><es><n>',
  '20240205T192835Z<n><bs><n><tz>-05<n><of>-0500<n><ot>-0500<n><s>19700101T000000<n><es><n>',
  '20240205T192835Z<n><bs><n><tz>-04<n><of>-0400<n><ot>-0400<n><s>19700101T000000<n><es><n>',
  '20240205T192835Z<n><bs><n><tz>-03<n><of>-0300<n><ot>-0300<n><s>19700101T000000<n><es><n>',
  '20240205T192835Z<n><bs><n><tz>-02<n><of>-0200<n><ot>-0200<n><s>19700101T000000<n><es><n>',
  '20240205T192835Z<n><bs><n><tz>-12<n><of>-1200<n><ot>-1200<n><s>19700101T000000<n><es><n>',
  '20240205T192835Z<n><bs><n><tz>-11<n><of>-1100<n><ot>-1100<n><s>19700101T000000<n><es><n>',
  '20240205T192835Z<n><bs><n><tz>-10<n><of>-1000<n><ot>-1000<n><s>19700101T000000<n><es><n>',
  '20240205T192835Z<n><bs><n><tz>-01<n><of>-0100<n><ot>-0100<n><s>19700101T000000<n><es><n>',
  '20240205T192834Z<n><bs><n><tz>-04<n><of>-0300<n><ot>-0400<n><s>19700405T000000<n><r>FREQ=YEARLY;BYMONTH=4;BYDAY=1SU<n><es><n><bd><n><tz>-03<n><of>-0400<n><ot>-0300<n><s>19700906T000000<n><r>FREQ=YEARLY;BYMONTH=9;BYDAY=1SU<n><ed><n>',
  '20240205T192834Z<n><bs><n><tz>NST<n><of>-0230<n><ot>-0330<n><s>19701101T020000<n><r>FREQ=YEARLY;BYMONTH=11;BYDAY=1SU<n><es><n><bd><n><tz>NDT<n><of>-0330<n><ot>-0230<n><s>19700308T020000<n><r>FREQ=YEARLY;BYMONTH=3;BYDAY=2SU<n><ed><n>',
  '20240205T192834Z<n><bd><n><tz>ADT<n><of>-0400<n><ot>-0300<n><s>19700308T020000<n><r>FREQ=YEARLY;BYMONTH=3;BYDAY=2SU<n><ed><n><bs><n><tz>AST<n><of>-0300<n><ot>-0400<n><s>19701101T020000<n><r>FREQ=YEARLY;BYMONTH=11;BYDAY=1SU<n><es><n>',
  '20240205T192834Z<n><bs><n><tz>-04<n><of>-0400<n><ot>-0400<n><s>19700101T000000<n><es><n>',
  '20240205T192834Z<n><bs><n><tz>-03<n><of>-0300<n><ot>-0300<n><s>19700101T000000<n><es><n>',
  '20240205T192834Z<n><bs><n><tz>-02<n><of>-0200<n><ot>-0200<n><s>19700101T000000<n><es><n>',
  '20240205T192834Z<n><bs><n><tz>-05<n><of>-0500<n><ot>-0500<n><s>19700101T000000<n><es><n>',
  '20240205T192834Z<n><bs><n><tz>ACST<n><of>+1030<n><ot>+0930<n><s>19700405T030000<n><r>FREQ=YEARLY;BYMONTH=4;BYDAY=1SU<n><es><n><bd><n><tz>ACDT<n><of>+0930<n><ot>+1030<n><s>19701004T020000<n><r>FREQ=YEARLY;BYMONTH=10;BYDAY=1SU<n><ed><n>',
  '20240205T192834Z<n><bs><n><tz>AWST<n><of>+0800<n><ot>+0800<n><s>19700101T000000<n><es><n>',
  '20240205T192834Z<n><bs><n><tz>AEST<n><of>+1100<n><ot>+1000<n><s>19700405T030000<n><r>FREQ=YEARLY;BYMONTH=4;BYDAY=1SU<n><es><n><bd><n><tz>AEDT<n><of>+1000<n><ot>+1100<n><s>19701004T020000<n><r>FREQ=YEARLY;BYMONTH=10;BYDAY=1SU<n><ed><n>',
  '20240205T192834Z<n><bd><n><tz>AEDT<n><of>+1000<n><ot>+1100<n><s>19701004T020000<n><r>FREQ=YEARLY;BYMONTH=10;BYDAY=1SU<n><ed><n><bs><n><tz>AEST<n><of>+1100<n><ot>+1000<n><s>19700405T030000<n><r>FREQ=YEARLY;BYMONTH=4;BYDAY=1SU<n><es><n>',
  '20240205T192834Z<n><bs><n><tz>AEST<n><of>+1000<n><ot>+1000<n><s>19700101T000000<n><es><n>',
  '20240205T192834Z<n><bs><n><tz>ACST<n><of>+0930<n><ot>+0930<n><s>19700101T000000<n><es><n>',
  '20240205T192834Z<n><bs><n><tz>+1030<n><of>+1100<n><ot>+1030<n><s>19700405T020000<n><r>FREQ=YEARLY;BYMONTH=4;BYDAY=1SU<n><es><n><bd><n><tz>+11<n><of>+1030<n><ot>+1100<n><s>19701004T020000<n><r>FREQ=YEARLY;BYMONTH=10;BYDAY=1SU<n><ed><n>',
  '20240205T192834Z<n><bs><n><tz>+0845<n><of>+0845<n><ot>+0845<n><s>19700101T000000<n><es><n>',
  '20240205T192834Z<n><bs><n><tz>-01<n><of>-0100<n><ot>-0100<n><s>19700101T000000<n><es><n>',
  '20240205T192834Z<n><bd><n><tz>+00<n><of>-0100<n><ot>+0000<n><s>19700329T000000<n><r>FREQ=YEARLY;BYMONTH=3;BYDAY=-1SU<n><ed><n><bs><n><tz>-01<n><of>+0000<n><ot>-0100<n><s>19701025T010000<n><r>FREQ=YEARLY;BYMONTH=10;BYDAY=-1SU<n><es><n>',
  '20240205T192834Z<n><bs><n><tz>WITA<n><of>+0800<n><ot>+0800<n><s>19700101T000000<n><es><n>',
  '20240205T192834Z<n><bs><n><tz>WIB<n><of>+0700<n><ot>+0700<n><s>19700101T000000<n><es><n>',
  '20240205T192834Z<n><bs><n><tz>PST<n><of>+0800<n><ot>+0800<n><s>19700101T000000<n><es><n>',
  '20240205T192834Z<n><bs><n><tz>IST<n><of>+0530<n><ot>+0530<n><s>19700101T000000<n><es><n>',
  '20240205T192834Z<n><bs><n><tz>+0545<n><of>+0545<n><ot>+0545<n><s>19700101T000000<n><es><n>',
  '20240205T192834Z<n><bs><n><tz>PKT<n><of>+0500<n><ot>+0500<n><s>19700101T000000<n><es><n>',
  '20240205T192834Z<n><bs><n><tz>+0430<n><of>+0430<n><ot>+0430<n><s>19700101T000000<n><es><n>',
  '20240205T192834Z<n><bs><n><tz>WIT<n><of>+0900<n><ot>+0900<n><s>19700101T000000<n><es><n>',
  '20240205T192834Z<n><bd><n><tz>EEST<n><of>+0200<n><ot>+0300<n><s>19700328T020000<n><r>FREQ=YEARLY;BYMONTH=3;BYDAY=-1SA<n><ed><n><bs><n><tz>EET<n><of>+0300<n><ot>+0200<n><s>19701024T020000<n><r>FREQ=YEARLY;BYMONTH=10;BYDAY=-1SA<n><es><n>',
  '20240205T192834Z<n><bs><n><tz>+0530<n><of>+0530<n><ot>+0530<n><s>19700101T000000<n><es><n>',
  '20240205T192834Z<n><bd><n><tz>EEST<n><of>+0200<n><ot>+0300<n><s>19700329T000000<n><r>FREQ=YEARLY;BYMONTH=3;BYDAY=-1SU<n><ed><n><bs><n><tz>EET<n><of>+0300<n><ot>+0200<n><s>19701025T000000<n><r>FREQ=YEARLY;BYMONTH=10;BYDAY=-1SU<n><es><n>',
  '20240205T192834Z<n><bd><n><tz>+02<n><of>+0000<n><ot>+0200<n><s>19700329T010000<n><r>FREQ=YEARLY;BYMONTH=3;BYDAY=-1SU<n><ed><n><bs><n><tz>+00<n><of>+0200<n><ot>+0000<n><s>19701025T030000<n><r>FREQ=YEARLY;BYMONTH=10;BYDAY=-1SU<n><es><n>',
  '20240205T192834Z<n><bs><n><tz>AST<n><of>-0400<n><ot>-0400<n><s>19700101T000000<n><es><n>',
  '20240205T192834Z<n><bs><n><tz>-02<n><of>-0100<n><ot>-0200<n><s>19701025T000000<n><r>FREQ=YEARLY;BYMONTH=10;BYDAY=-1SU<n><es><n><bd><n><tz>-01<n><of>-0200<n><ot>-0100<n><s>19700328T230000<n><r>FREQ=YEARLY;BYMONTH=3;BYDAY=-1SA<n><ed><n>',
  '20240205T192834Z<n><bs><n><tz>CST<n><of>-0500<n><ot>-0600<n><s>19701101T020000<n><r>FREQ=YEARLY;BYMONTH=11;BYDAY=1SU<n><es><n><bd><n><tz>CDT<n><of>-0600<n><ot>-0500<n><s>19700308T020000<n><r>FREQ=YEARLY;BYMONTH=3;BYDAY=2SU<n><ed><n>',
  '20240205T192834Z<n><bd><n><tz>-01<n><of>-0200<n><ot>-0100<n><s>19700328T230000<n><r>FREQ=YEARLY;BYMONTH=3;BYDAY=-1SA<n><ed><n><bs><n><tz>-02<n><of>-0100<n><ot>-0200<n><s>19701025T000000<n><r>FREQ=YEARLY;BYMONTH=10;BYDAY=-1SU<n><es><n>',
  '20240205T192834Z<n><bd><n><tz>-02<n><of>-0300<n><ot>-0200<n><s>19700308T020000<n><r>FREQ=YEARLY;BYMONTH=3;BYDAY=2SU<n><ed><n><bs><n><tz>-03<n><of>-0200<n><ot>-0300<n><s>19701101T020000<n><r>FREQ=YEARLY;BYMONTH=11;BYDAY=1SU<n><es><n>',
  '20240205T192834Z<n><bs><n><tz>EST<n><of>-0400<n><ot>-0500<n><s>19701101T020000<n><r>FREQ=YEARLY;BYMONTH=11;BYDAY=1SU<n><es><n><bd><n><tz>EDT<n><of>-0500<n><ot>-0400<n><s>19700308T020000<n><r>FREQ=YEARLY;BYMONTH=3;BYDAY=2SU<n><ed><n>',
  '20240205T192834Z<n><bs><n><tz>AST<n><of>-0300<n><ot>-0400<n><s>19701101T020000<n><r>FREQ=YEARLY;BYMONTH=11;BYDAY=1SU<n><es><n><bd><n><tz>ADT<n><of>-0400<n><ot>-0300<n><s>19700308T020000<n><r>FREQ=YEARLY;BYMONTH=3;BYDAY=2SU<n><ed><n>',
  '20240205T192835Z<n><bd><n><tz>-03<n><of>-0400<n><ot>-0300<n><s>19701004T000000<n><r>FREQ=YEARLY;BYMONTH=10;BYDAY=1SU<n><ed><n><bs><n><tz>-04<n><of>-0300<n><ot>-0400<n><s>19700322T000000<n><r>FREQ=YEARLY;BYMONTH=3;BYDAY=4SU<n><es><n>',
  '20240205T192834Z<n><bs><n><tz>CAT<n><of>+0200<n><ot>+0200<n><s>19700101T000000<n><es><n>',
  '20240205T192834Z<n><bs><n><tz>CET<n><of>+0100<n><ot>+0100<n><s>19700101T000000<n><es><n>',
  '20240205T192834Z<n><bs><n><tz>WAT<n><of>+0100<n><ot>+0100<n><s>19700101T000000<n><es><n>',
  '20240205T192834Z<n><bs><n><tz>SAST<n><of>+0200<n><ot>+0200<n><s>19700101T000000<n><es><n>',
  '20240205T192834Z<n><bs><n><tz>+01<n><of>+0100<n><ot>+0100<n><s>19700101T000000<n><es><n>',
];
function tzlib_get_content(tzName) {
  const nameParts = tzName.split('/');
  if (
    (nameParts.length === 3 &&
      (!tzlibZonesDB[`${nameParts[0]}`] ||
        !tzlibZonesDB[`${nameParts[0]}`][`${nameParts[1]}`] ||
        !tzlibZonesDB[`${nameParts[0]}`][`${nameParts[1]}`][`${nameParts[2]}`])) ||
    (nameParts.length === 2 &&
      (!tzlibZonesDB[`${nameParts[0]}`] || !tzlibZonesDB[`${nameParts[0]}`][`${nameParts[1]}`])) ||
    (nameParts.length === 1 && !tzlibZonesDB[`${nameParts[0]}`])
  ) {
    console.error('Given timezone not valid.');
    return '';
  }
  if (nameParts.length === 3) {
    return [
      tzlibZonesDB[`${nameParts[0]}`][`${nameParts[1]}`][`${nameParts[2]}`][0],
      tzlib_enrich_data(
        tzlibZonesDetailsDB[tzlibZonesDB[`${nameParts[0]}`][`${nameParts[1]}`][`${nameParts[2]}`][1]],
      ),
    ];
  }
  if (nameParts.length === 2) {
    return [
      tzlibZonesDB[`${nameParts[0]}`][`${nameParts[1]}`][0],
      tzlib_enrich_data(tzlibZonesDetailsDB[tzlibZonesDB[`${nameParts[0]}`][`${nameParts[1]}`][1]]),
    ];
  }
  return [
    tzlibZonesDB[`${nameParts[0]}`][0],
    tzlib_enrich_data(tzlibZonesDetailsDB[tzlibZonesDB[`${nameParts[0]}`][1]]),
  ];
}
function tzlib_enrich_data(string) {
  const shortenerMap = {
    '<br>': '<n>',
    'TZNAME:': '<tz>',
    'TZOFFSETFROM:': '<of>',
    'TZOFFSETTO:': '<ot>',
    'DTSTART:': '<s>',
    'RRULE:': '<r>',
    'BEGIN:DAYLIGHT': '<bd>',
    'END:DAYLIGHT': '<ed>',
    'BEGIN:STANDARD': '<bs>',
    'END:STANDARD': '<es>',
  };
  for (const [key, value] of Object.entries(shortenerMap)) {
    string = string.replaceAll(value, key);
  }
  return string;
}
// eslint-disable-next-line @typescript-eslint/no-unused-vars
function tzlib_get_ical_block(tzName, jsonType = false) {
  const tzBlock = tzlib_get_content(tzName);
  if (tzBlock[1] == null || tzBlock[1] == '') {
    return '';
  }
  const location = (function () {
    if (tzBlock[0] == '') {
      return tzName;
    } else {
      return tzBlock[0];
    }
  })();
  const tzidLine = 'TZID=' + location;
  const output = [
    'BEGIN:VTIMEZONE\r\nTZID:' +
      location +
      '\r\nX-LIC-LOCATION:' +
      location +
      '\r\nLAST-MODIFIED:' +
      tzBlock[1].replace(/[^\w_\-:,;=+/<br>]/g, '').replace(/<br>/g, '\r\n') +
      'END:VTIMEZONE',
    tzidLine,
  ];
  if (jsonType) {
    return JSON.stringify(output);
  }
  return output;
}
// eslint-disable-next-line @typescript-eslint/no-unused-vars
function tzlib_get_offset(tzName, isoDate, isoTime) {
  const tzBlock = tzlib_get_content(tzName);
  if (tzBlock[1] == null || tzBlock[1] == '') {
    return '';
  }
  if (!isoDate.match(/^\d{4}-\d{2}-\d{2}$/)) {
    console.error('offset calculation failed: date misspelled [-> YYYY-MM-DD]');
    return '';
  }
  if (!isoTime.match(/^\d{2}:\d{2}$/)) {
    console.error('offset calculation failed: time misspelled [-> hh:mm]');
    return '';
  }
  if (!tzBlock[1].match(/BEGIN:DAYLIGHT/i)) {
    return tzBlock[1].match(/TZOFFSETTO:([+|-]\d{4})/i)[1];
  }
  const dateString = isoDate + 'T' + isoTime + ':00';
  const date = new Date(dateString);
  const dateYear = date.getFullYear();
  const dateMonth = date.getMonth() + 1;
  const dateDay = date.getDate();
  const dateHour = date.getHours();
  const timezoneData = tzBlock[1].replace(/[^\w_\-:,;=+/<br>]/g, '').split('<br>');
  const tzBreakpoints = { 1: {}, 2: {} };
  let breakpointCount = 0;
  for (let i = 0; i < timezoneData.length; i++) {
    if (timezoneData[`${i}`].startsWith('TZOFFSETTO')) {
      breakpointCount++;
      tzBreakpoints[`${breakpointCount}`].offset = timezoneData[`${i}`].split(':')[1];
    }
    if (timezoneData[`${i}`].startsWith('DTSTART')) {
      tzBreakpoints[`${breakpointCount}`].hour = parseInt(timezoneData[`${i}`].substr(17, 2));
    }
    if (timezoneData[`${i}`].startsWith('RRULE')) {
      let rruleParts = timezoneData[`${i}`].split(';');
      let rruleMonth = parseInt(rruleParts[1].split('=')[1]);
      tzBreakpoints[`${breakpointCount}`].month = parseInt(rruleMonth);
      tzBreakpoints[`${breakpointCount}`].day = rruleParts[2].split('=')[1];
    }
  }
  if (tzBreakpoints[1].month > tzBreakpoints[2].month) {
    [tzBreakpoints[1], tzBreakpoints[2]] = [tzBreakpoints[2], tzBreakpoints[1]];
  }
  if (dateMonth != tzBreakpoints[1].month && dateMonth != tzBreakpoints[2].month) {
    if (dateMonth < tzBreakpoints[1].month || dateMonth > tzBreakpoints[2].month) {
      return tzBreakpoints[2].offset;
    } else {
      return tzBreakpoints[1].offset;
    }
  }
  const theCase = (function () {
    return Object.keys(tzBreakpoints).find((key) => tzBreakpoints[`${key}`].month == dateMonth);
  })();
  const helperArrayWeekdays = ['SU', 'MO', 'TU', 'WE', 'TH', 'FR', 'SA'];
  const numberDays = new Date(dateYear, dateMonth, 0).getDate();
  let weekdayCount = new Date(dateYear, dateMonth - 1, 1).getDay();
  const weekdays = { SU: {}, MO: {}, TU: {}, WE: {}, TH: {}, FR: {}, SA: {} };
  for (let d = 1; d <= numberDays; d++) {
    const occurence = Object.keys(weekdays[helperArrayWeekdays[`${weekdayCount}`]]).length + 1;
    weekdays[helperArrayWeekdays[`${weekdayCount}`]][`${occurence}`] = d;
    weekdayCount++;
    if (weekdayCount == 7) {
      weekdayCount = 0;
    }
  }
  const actualDay = (function () {
    if (tzBreakpoints[`${theCase}`].day[0] == '-') {
      const breakpointWeekday = tzBreakpoints[`${theCase}`].day.substr(2, 2);
      const dayIndex =
        Object.keys(weekdays[`${breakpointWeekday}`]).length +
        1 -
        parseInt(tzBreakpoints[`${theCase}`].day[1]);
      return weekdays[`${breakpointWeekday}`][`${dayIndex}`];
    } else {
      const breakpointWeekday = tzBreakpoints[`${theCase}`].day.substr(1, 2);
      return weekdays[`${breakpointWeekday}`][tzBreakpoints[`${theCase}`].day[0]];
    }
  })();
  if (dateDay > actualDay || (dateDay == actualDay && dateHour >= tzBreakpoints[`${theCase}`].hour)) {
    return tzBreakpoints[`${theCase}`].offset;
  }
  const fallbackCase = (function () {
    if (theCase == 1) {
      return 2;
    } else {
      return 1;
    }
  })();
  return tzBreakpoints[`${fallbackCase}`].offset;
}
let tzlibZoneNames = [];
// eslint-disable-next-line @typescript-eslint/no-unused-vars
function tzlib_get_timezones(jsonType = false) {
  if (tzlibZoneNames.length == 0) {
    tzlibZoneNames = (function () {
      let namesArr = [];
      for (const [key, value] of Object.entries(tzlibZonesDB)) {
        if (typeof value === 'object' && !Array.isArray(value)) {
          for (const [key2, value2] of Object.entries(value)) {
            if (typeof value2 === 'object' && !Array.isArray(value2)) {
              for (const [key3] of Object.entries(value2)) {
                namesArr.push(key + '/' + key2 + '/' + key3);
              }
            } else {
              namesArr.push(key + '/' + key2);
            }
          }
        } else {
          namesArr.push(key);
        }
      }
      return namesArr;
    })();
  }
  if (jsonType) {
    return JSON.stringify(tzlibZoneNames);
  }
  return tzlibZoneNames;
}
export { tzlib_get_ical_block, tzlib_get_offset, tzlib_get_timezones };
